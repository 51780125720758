<template>
    <div class="page-container">
        <p class="main-title" >{{$route.query.main_title}}<a @click="reload"><i class="el-icon-refresh"></i></a></p>  
        <div class="page-desc">
			资产入库是将线下的资产采购后，进行线上入库到系统中统一管理的操作。
         </div>   
		<TableVue ref="c_table"></TableVue>
	</div>   
</template>
<script>
import TableVue from './Common/Table.vue'
export default {
    components:{
		TableVue
    },
	 inject:['reload'],
   data() {
      return {
      }
   },
   created(){

		this.$nextTick(()=>{
			this.$refs.c_table.rUrl = {
				index:'AssetStorage/index',
				add:'AssetStorage/add',
				edit:'AssetStorage/edit',
				del:'AssetStorage/del',
				export:'AssetStorage/export' 
			} 
			this.$refs.c_table.cardName =  '资产入库单'
			this.$refs.c_table.storageType = 1
			this.$refs.c_table.expandStatus = true
			this.$refs.c_table.getData();
		})
   },
   mounted(){
		
   },
   methods:{
		
   }

}
</script>

</style>